export enum ACCOUNTING_STORE_STATE {
  ACCOUNTING_OBJECTS = 'Accounting/ACCOUNTING_OBJECTS',
  AUTOACCOUNTING_RULES = 'Accounting/AUTOACCOUNTING_RULES',
  RECEIPT_ACCOUNTING_ENTRIES = 'Accounting/RECEIPT_ACCOUNTING_ENTRIES',
  TRIP_ACCOUNTING_ENTRIES = 'Accounting/TRIP_ACCOUNTING_ENTRIES',
  RECEIPT_ACCOUNTING_REFS = 'Accounting/RECEIPT_ACCOUNTING_REFS',
  TRIP_ACCOUNTING_REFS = 'Accounting/TRIP_ACCOUNTING_REFS',
  MANAGED_REFS = 'Accounting/MANAGED_REFS'
}

export enum ACCOUNTING_STORE_GETTERS {
  ACCOUNTING_OBJECTS = 'Accounting/ACCOUNTING_OBJECTS',
  AUTOACCOUNTING_RULES = 'Accounting/AUTOACCOUNTING_RULES',
  RECEIPT_ACCOUNTING_ENTRIES = 'Accounting/RECEIPT_ACCOUNTING_ENTRIES',
  TRIP_ACCOUNTING_ENTRIES = 'Accounting/TRIP_ACCOUNTING_ENTRIES',
  RECEIPT_ACCOUNTING_REFS = 'Accounting/RECEIPT_ACCOUNTING_REFS',
  TRIP_ACCOUNTING_REFS = 'Accounting/TRIP_ACCOUNTING_REFS',
  MANAGED_REFS = 'Accounting/MANAGED_REFS'
}

export enum ACCOUNTING_STORE_ACTIONS {
  GET_ACCOUNTING_OBJECTS = 'Accounting/GET_ACCOUNTING_OBJECTS',
  GET_RECEIPT_ACCOUNTING_ENTRIES = 'Accounting/GET_RECEIPT_ACCOUNTING_ENTRIES',
  GET_TRIP_ACCOUNTING_ENTRIES = 'Accounting/GET_TRIP_ACCOUNTING_ENTRIES',
  GET_RECEIPT_ACCOUNTING_REFS = 'Accounting/GET_RECEIPT_ACCOUNTING_REFS',
  GET_TRIP_ACCOUNTING_REFS = 'Accounting/GET_TRIP_ACCOUNTING_REFS',
  GET_MANAGED_REFS = 'Accounting/GET_MANAGED_REFS',
  GET_AUTOMATION_RULES = 'Accounting/GET_AUTOMATION_RULES',
  UPDATE_AUTOMATION_RULES = 'Accounting/UPDATE_AUTOMATION_RULES',
  UPDATE_ACCOUNTING_REF = 'Accounting/UPDATE_ACCOUNTING_REF',
  DELETE_RECEIPT_ACCOUNTING_REF = 'Accounting/DELETE_RECEIPT_ACCOUNTING_REF',
  DELETE_TRIP_ACCOUNTING_REF = 'Accounting/DELETE_TRIP_ACCOUNTING_REF',
  CREATE_ACCOUNTING_OBJECT = 'Accounting/CREATE_ACCOUNTING_OBJECT',
  UPDATE_ACCOUNTING_OBJECT = 'Accounting/UPDATE_ACCOUNTING_OBJECT',
  DELETE_ACCOUNTING_OBJECT = 'Accounting/DELETE_ACCOUNTING_OBJECT',
  DOWNLOAD_CONTEXT_TEMPLATE = 'Accounting/DOWNLOAD_TEMPLATE',
  UPDATE_CONTEXT_FROM_CSV = 'Accounting/UPDATE_CONTEXT_FROM_CSV',
  UPDATE_ACCOUNTING_CONFIG = 'Accounting/UPDATE_ACCOUNTING_CONFIG',
  ENABLE_AUTOACCOUNTING = 'Accounting/ENABLE_AUTOACCOUNTING'
}

export enum ACCOUNTING_STORE_MUTATIONS {
  SET_ACCOUNTING_OBJECTS = 'Accounting/SET_ACCOUNTING_OBJECTS',
  SET_ACCOUNTING_OBJECT = 'Accounting/SET_ACCOUNTING_OBJECT',
  SET_AUTOACCOUNTING_RULES = 'Accounting/SET_AUTOACCOUNTING_RULES',
  DELETE_ACCOUNTING_OBJECT = 'Accounting/DELETE_ACCOUNTING_OBJECT',
  SET_RECEIPT_ACCOUNTING_ENTRIES = 'Accounting/SET_RECEIPT_ACCOUNTING_ENTRIES',
  SET_TRIP_ACCOUNTING_ENTRIES = 'Accounting/SET_TRIP_ACCOUNTING_ENTRIES',
  SET_RECEIPT_ACCOUNTING_REFS = 'Accounting/SET_RECEIPT_ACCOUNTING_REFS',
  SET_TRIP_ACCOUNTING_REFS = 'Accounting/SET_TRIP_ACCOUNTING_REFS',
  SET_MANAGED_REFS = 'Accounting/SET_MANAGED_REFS',
  DELETE_RECEIPT_ACCOUNTING_ENTRIES = 'Accounting/DELETE_RECEIPT_ACCOUNTING_ENTRIES',
  DELETE_RECEIPT_ACCOUNTING_REF = 'Accounting/DELETE_RECEIPT_ACCOUNTING_REF',
  DELETE_TRIP_ACCOUNTING_REF = 'Accounting/DELETE_TRIP_ACCOUNTING_REF'
}

export enum REFERENCE_OBJECT_TYPE {
  COST_TYPE = 'COST_TYPE',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  RECEIPT = 'RECEIPT',
  VAT_ROW = 'VAT_ROW',
  ITEM_ROW = 'ITEM_ROW',
  TRIP = 'TRIP',
  TRIP_MILEAGE = 'TRIP_MILEAGE',
  TRIP_ALLOWANCE = 'TRIP_ALLOWANCE'
}

export enum REFERENCE_OBJECT_TYPE_NAME {
  COST_TYPE = 'costType',
  PAYMENT_METHOD = 'paidBy',
  RECEIPT = 'document',
  ITEM_ROW = 'itemRows',
  TRIP = 'oneReport',
  TRIP_MILEAGE = 'mileage',
  TRIP_ALLOWANCE = 'dailyAllowance'
}

export enum ACCOUNTING_OBJECT_TYPE {
  ITEM_CODE = 'itemCode',
  COST_OBJECTIVE = 'costObjective',
  PROJECT = 'project',
  ACCOUNT = 'account',
  DIMENSION = 'dimension',
  DEPARTMENT = 'department',
  VAT_CODE = 'VATCode'
}

export enum ACCOUNTING_ENTRY_TYPE {
  ACCOUNT = 'account',
  VAT_CODE = 'vatCode'
}

export enum ACCOUNTING_ENTRY_REFERENCE_TYPE {
  RECEIPT = 'document',
  MILEAGE = 'mileage',
  DAILY_ALLOWANCE = 'dailyAllowance'
}

export enum FILTER_VECTOR {
  SUBMITTER_EMAIL = 'submitterEmail',
  SUPPLIER_NAME = 'supplierName',
  SUPPLIER_REG_CODE = 'supplierRegCode',
  SUPPLIER_VAT_NUMBER = 'supplierVATNumber',
  SUPPLIER_COUNTRY_CODE = 'supplierCountryCode',
  SUPPLIER_BANK_ACCOUNT_NR = 'supplierBankAccountNr',
  ORDER_ID = 'orderId',
  ITEM_ROWS_VAT_RATE = 'itemRows[].VATrate',
  ITEM_ROWS_CODE = 'itemRows[].code',
  ITEM_ROWS_DESCRIPTION = 'itemRows[].description',
  COST_TYPE = 'costType',
  PAID_WITH = 'paidWith',
  BILLING_TYPE = 'billingType',
  COMMENT = 'comment',
  COST_TYPE_NAME = 'costTypeName',
  PAYMENT_METHOD_TYPE = 'paymentMethodType',
  PAYMENT_METHOD_CARD_NR = 'paymentCardNr',
  PAYMENT_METHOD_ACCOUNT_NR = 'paymentBankAccountNr',
  PAYMENT_REF_NR = 'paymentRefNr'
}

// TODO: translations

export const FILTER_VECTOR_TEXT = {
  [FILTER_VECTOR.SUBMITTER_EMAIL]: 'submittedBy',
  [FILTER_VECTOR.SUPPLIER_NAME]: 'supplierName',
  [FILTER_VECTOR.SUPPLIER_REG_CODE]: 'supplierRegNumber',
  [FILTER_VECTOR.SUPPLIER_VAT_NUMBER]: 'supplierVATNumber',
  [FILTER_VECTOR.SUPPLIER_COUNTRY_CODE]: 'Supplier country',
  [FILTER_VECTOR.ORDER_ID]: 'Order id',
  [FILTER_VECTOR.ITEM_ROWS_VAT_RATE]: 'vrt',
  [FILTER_VECTOR.ITEM_ROWS_CODE]: 'code',
  [FILTER_VECTOR.ITEM_ROWS_DESCRIPTION]: 'description',
  [FILTER_VECTOR.COST_TYPE]: 'costType',
  [FILTER_VECTOR.PAID_WITH]: 'paidBy',
  [FILTER_VECTOR.BILLING_TYPE]: 'billingType',
  [FILTER_VECTOR.COMMENT]: 'comment',
  [FILTER_VECTOR.COST_TYPE_NAME]: 'costType',
  [FILTER_VECTOR.PAYMENT_METHOD_TYPE]: 'paidBy',
  [FILTER_VECTOR.PAYMENT_METHOD_CARD_NR]: 'cardLfd',
  [FILTER_VECTOR.PAYMENT_METHOD_ACCOUNT_NR]: 'bankAccount',
  [FILTER_VECTOR.PAYMENT_REF_NR]: 'refNum',
  [FILTER_VECTOR.SUPPLIER_BANK_ACCOUNT_NR]: 'Supplier bank account'
}

export enum TARGET_VECTOR {
  ACCOUNTING = 'accounting',
  COST_TYPE = 'costType.accounting',
  PAYMENT_METHOD = 'paymentMethod.accounting',
  ITEM_ROWS = 'itemRows[].accounting',
  REPORT = 'report.accounting',
  MILEAGE = 'mileage.accounting'
  // DAILY_ALLOWANCE = 'allowance.accounting'
  // TODO: report.documents[].* where * is any from the document vectors
}

export const TARGET_VECTOR_TEXT = {
  [TARGET_VECTOR.ACCOUNTING]: 'document', // ??? Document
  [TARGET_VECTOR.ITEM_ROWS]: 'itemRows',
  [TARGET_VECTOR.COST_TYPE]: 'costType',
  [TARGET_VECTOR.PAYMENT_METHOD]: 'paidBy',
  [TARGET_VECTOR.MILEAGE]: 'mileage',
  [TARGET_VECTOR.REPORT]: 'report' //TODO:
}
